import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/react-best-practices-patterns",
  "date": "2015-10-27",
  "title": "REACT BEST PRACTICES & PATTERNS",
  "author": "admin",
  "tags": ["development", "javascript", "react.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "In this article we discuss about React best practices & patterns for writing React components, and the recommendations here aim at coding convensions and patterns."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Naming Conventions`}</h2>
    <p>{`Let's look at the file and folder naming convention for React.js components. `}</p>
    <h3>{`Directory Structure`}</h3>
    <p>{`React component directory structure can be organized as follows. `}</p>
    <pre><code parentName="pre" {...{}}>{`/actions/ActionTypes.js
/components/common/Link.js
/components/common/...
/components/forms/TextBox.js
/components/forms/...
/components/layout/App.js
/components/layout/Navigation.js
/components/layout/...
/components/pages/Home.js
/components/pages/Account/index.js
/components/pages/...
/core/...
/constants/...
/stores/...
`}</code></pre>
    <h2>{`Code Organization`}</h2>
    <h3>{`Classes`}</h3>
    <p>{`The class definition for can be organized as follows: `}</p>
    <ul>
      <li parentName="ul">{`class definition`}<ul parentName="li">
          <li parentName="ul">{`constructor`}<ul parentName="li">
              <li parentName="ul">{`event-handlers`}</li>
            </ul></li>
          <li parentName="ul">{`component life-cycle events`}</li>
          <li parentName="ul">{`getters`}</li>
          <li parentName="ul">{`setters`}</li>
        </ul></li>
      <li parentName="ul">{`defaultProps`}</li>
      <li parentName="ul">{`propTypes`}</li>
    </ul>
    <p>{`Let's look at an example: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class Car extends React.Component {
  constructor (props) {
    super(props);

    this.state = { running: false };

    this.handleClick = () => {
      this.setState({running: !this.state.running});
    };
  }

  componentWillMount () {
    // add event listeners (Flux Store, WebSocket, document, etc.)
  },

  componentDidMount () {
    // React.getDOMNode()
  },

  componentWillUnmount () {
    // remove event listeners (Flux Store, WebSocket, document, etc.)
  },

  get engineStatus () {
    return (this.state.running) ? "is running" : "is off";
  }

  render () {
    return (
      <div onClick={this.handleClick}>
        {this.props.make} {this.engineStatus}
      </div>
    );
  },
}

Car.defaultProps = {
  make: 'Toyota'
};

Car.propTypes = {
  make: React.PropTypes.string
};
`}</code></pre>
    <h3>{`Class Names`}</h3>
    <p>{` Use `}<strong parentName="p">{`classNames`}</strong>{` to manage conditional classes. `}</p>
    <h3>{`bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`get classes () {
  let classes = ['my-component'];

  if (this.state.active) {
    classes.push('my-omponent-active');
  }

  return classes.join(' ');
}

render () {
  return <div className={this.classes} />;
}
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`render () {
  let classes = {
    'MyComponent': true,
    'MyComponent--active': this.state.active
  };

  return <div className={classnames(classes)} />;
}
`}</code></pre>
    <h2>{`Formatting Props`}</h2>
    <p>{`Wrap props on newlines for exactly 2 or more. `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Car
 make="Oldsmobile" />
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Car make="Oldsmobile" />
`}</code></pre>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Car make="Oldsmobile" year="1950" name="88"/>
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Car
 make="Oldsmobile"
 year="1950"
 name="88"/>
`}</code></pre>
    <h2>{`Computed Props`}</h2>
    <p>{`Use getters to name computed properties. `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`makeAndName () {
  return \`\${this.props.make} \${this.props.name}\`;
}
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`get brand () {
  return \`\${this.props.make} \${this.props.name}\`;
}
`}</code></pre>
    <h2>{`Computed State`}</h2>
    <p>{` Prefix compound state `}<inlineCode parentName="p">{`getters`}</inlineCode>{` with a verb for readability. `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`isAccelerating () {
  return this.state.isRunning && this.state.pushingPedal;
}
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`get isAccelerating () {
  return this.state.isRunning && this.state.pushingPedal;
}
`}</code></pre>
    <p>{` These methods MUST return a boolean value. `}</p>
    <h2>{`Ternary render statements`}</h2>
    <p>{` Instead of sub-render statements, use ternary. `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`renderRunning () {
  return <strong>{(this.state.isRunning) ? ' is running.' : ''}</strong>;
},

render () {
  return <div>{this.props.name}{this.renderRunning()}</div>;
}

`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`render () {
  return (
    <div>
      {this.props.name}
      {(this.state.running)
        ? <span>is running</span>
        : null
      }
     </div>
  );
}
`}</code></pre>
    <h2>{`View Components`}</h2>
    <p>{` Compose components into views. Don't create one-off components that merge `}<inlineCode parentName="p">{`layout`}</inlineCode>{` and `}<inlineCode parentName="p">{`domain components`}</inlineCode>{`. `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class EngineRow extends React.Component {
  render () {
    return (
      <div className="row">
        <Engine engine={this.state.running} />
       \`\`\`
    );
  }
}
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class EngineRow extends React.Component {
  render () {
    return <div className="row">{this.props.running} \`\`\`;
  }
}

class EngineStateView extends React.createClass {
  render () {
    return (
      <EngineRow>
        <Engine engine={this.state.running} />
      </EngineRow>
    );
  }
}
`}</code></pre>
    <h2>{`Container Components`}</h2>
    <p>{` A container does data fetching and then renders its corresponding sub-component. `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// CommentList.js

class CommentList extends React.Component {
  getInitialState () {
    return { comments: [] };
  }

  componentDidMount () {
    $.ajax({
      url: "/my-comments.json",
      dataType: 'json',
      success: function(comments) {
        this.setState({comments: comments});
      }.bind(this)
    });
  }

  render () {
    return (
      
        {this.state.comments.map(({body, author}) => {
          return * {body}—{author};
        })}
      
    );
  }
}
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// CommentList.js

class CommentList extends React.Component {
  render() {
    return (
      
        {this.props.comments.map(({body, author}) => {
          return * {body}—{author};
        })}
      
    );
  }
}
// CommentListContainer.js

class CommentListContainer extends React.Component {
  getInitialState () {
    return { comments: [] }
  }

  componentDidMount () {
    $.ajax({
      url: "/my-comments.json",
      dataType: 'json',
      success: function(comments) {
        this.setState({comments: comments});
      }.bind(this)
    });
  }

  render () {
    return <CommentList comments={this.state.comments} />;
  }
}
`}</code></pre>
    <h3>{`Cached State in render###`}</h3>
    <p>{` Do not keep state in `}<strong parentName="p">{`render`}</strong>{` `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`render () {
  let make = \`Make: \${this.props.make}\`;

  return <div>{name}</div>;
}
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`render () {
  return <div>{\`Make: \${this.props.make}\`}</div>;
}
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`get make () {
  return \`Make: \${this.props.make}\`;
}

render () {
  return <div>{this.make}</div>;
}
`}</code></pre>
    <h3>{`Compound Conditions`}</h3>
    <p>{` Don't put compound conditions in `}<inlineCode parentName="p">{`render`}</inlineCode>{` `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`render () {
  return <div>{if (this.state.started && this.state.running) { return 'Running..' }</div>;
}
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`get isEngineOn() {
  return this.state.started && this.state.running;
},

render() {
  return <div>{this.isEngineOn && 'Running..'}</div>;
}
`}</code></pre>
    <p>{` The best solution for this would use a `}<inlineCode parentName="p">{`container component`}</inlineCode>{` to manage state and pass new state down as props. `}</p>
    <h2>{`Existence of `}<inlineCode parentName="h2">{`prop`}</inlineCode></h2>
    <p>{` Do not check existence of `}<strong parentName="p">{`prop`}</strong>{` objects. Use `}<strong parentName="p">{`defaultProps`}</strong>{`. `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`render () {
  if (this.props.person) {
    return <div>{this.props.car.make}</div>;
  } else {
    return null;
  }
}
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class MyComponent extends React.Component {
  render() {
    return <div>{this.props.car.make}</div>;
  }
}

MyComponent.defaultProps = {
  car: {
    make: 'Toyota'
  }
};
`}</code></pre>
    <p>{`This is only where objects or arrays are used. Use `}<strong parentName="p">{`PropTypes.shape`}</strong>{` to clarify the types of nested data expected by the component. `}</p>
    <h2>{`Setting State from Props`}</h2>
    <p>{`Do not set state from props without obvious intent. `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`getInitialState () {
  return {
    items: this.props.items
  };
}
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`getInitialState () {
  return {
    items: this.props.initialItems
  };
}
`}</code></pre>
    <Message type="warn" title="Props in getInitialStet is an Anti-Pattern" content="According to React documentation, using props, passed down from parent, to generate state in getInitialState often leads to duplication of **source of truth**, i.e where the real data is. " mdxType="Message" />
    <h3>{`Naming Handler Methods`}</h3>
    <p>{`Name the handler methods after their triggering event. `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`click () { /*...*/ },

render () {
  return <div onClick={this.click} />;
}
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`handleClick () { /*...*/ },

render () {
  return <div onClick={this.handleClick} />;
}
`}</code></pre>
    <p>{`Handler names should: `}</p>
    <ul>
      <li parentName="ul">{`Be present-tense`}</li>
      <li parentName="ul">{`Begin with `}<strong parentName="li">{`handle`}</strong></li>
      <li parentName="ul">{`Follow with the name of the event. (eg:- `}<strong parentName="li">{`handleClick`}</strong>{`)`}</li>
    </ul>
    <h3>{`Naming Events`}</h3>
    <p>{` Use custom event names for `}<strong parentName="p">{`ownee`}</strong>{` events. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class Owner extends React.Component {
  handleUpdate () {
    // handle Ownee's onUpdate event
  }

  render () {
    return <Ownee onDelete={this.handleUpdate} />;
  }
}

class Ownee extends React.Component {
  render () {
    return <div onChange={this.props.onUpdate} />;
  }
}

Ownee.propTypes = {
  onUpdate: React.PropTypes.func.isRequired
};
`}</code></pre>
    <h3>{`Using PropTypes`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`PropTypes`}</inlineCode>{` to communicate expectations and log meaningful warnings. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`MyValidatedComponent.propTypes = {
  name: React.PropTypes.string
};
`}</code></pre>
    <p><strong parentName="p">{`MyValidatedComponent`}</strong>{` will log a warning if it receives `}<strong parentName="p">{`name`}</strong>{` of a type other than string. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Car brand=536 />
// Warning: Invalid prop \`brand\` of type \`number\` supplied to \`MyValidatedComponent\`, expected \`string\`.
`}</code></pre>
    <p>{` This component will now validate the presence of name. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Car />
// Warning: Required prop \`brand\` was not specified in \`Car\`
`}</code></pre>
    <h2>{`Using Entities`}</h2>
    <p>{` Use React's `}<strong parentName="p">{`String.fromCharCode()`}</strong>{` for special characters. `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<div>PiCO · Mascot</div>
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<div>{\`PiCO \${String.fromCharCode(183)} Mascot\`}</div>
`}</code></pre>
    <h3>{`Tables`}</h3>
    <p>{`The browser thinks you're dumb. But React doesn't. Always use `}<strong parentName="p">{`tbody`}</strong>{` in `}<strong parentName="p">{`table`}</strong>{` components. `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`render () {
  return (
    <table>
      <tr>...</tr>
    </table>
  );
}
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`render () {
  return (
    <table>
      <tbody>
        <tr>...</tr>
      </tbody>
    </table>
  );
}
`}</code></pre>
    <h2>{`JSX`}</h2>
    <p>{`By following a few general guidelines for handling JSX in components, it becomes far more readable. `}</p>
    <h3>{`Multi-line JSX`}</h3>
    <p>{`Write any JSX which contains nested elements across multiple lines with indentation to enhance readability. `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`return (<div><ComponentOne /><ComponentTwo /></div>);
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`return (
        <div>
            <ComponentOne />
            <ComponentTwo />
        </div>
    );
`}</code></pre>
    <Message type="info" title="Parenthesis" content="Parenthesis are not technically required with a single line JSX statement, but use them for the sake of consistency. " mdxType="Message" />
    <h3>{`Conditional JSX`}</h3>
    <p>{`When you have conditional elements that needs to be returned depending on state, props, or another condition, you can declare an empty variable at the top of the render function and only populate it with JSX if the condition is met. When the variable is returned in the render method return statement, it’ll either render the conditional elements, or nothing at all. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var optionalElement;

if (this.props.condition) {
    optionalElement = (<div> …  </div>);
}

return (
    <div>
        …
        {optionalElement}
        …
    </div>
);
`}</code></pre>
    <h3>{`In-line list iteration`}</h3>
    <p>{`Where possible, iterate over lists of data in-line in the returned JSX unless its internal logic is sufficiently complex enough to warrant moving outside of the return statement and populating an array for rendering. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`return (
    <div>
        {this.props.list.map(function(data, i) {
            return (<Component data={data} key={i} />)
        })}
    </div>
);
`}</code></pre>
    <h3>{`Indentation and new line for component attributes`}</h3>
    <p>{`When there are enough attributes on a component that displaying them inline becomes untidy (usually 3 or more), I always display them on multiple lines and indent each one. `}</p>
    <h3>{`Bad`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Component attribute={...} anotherAttribute={...} attributeThree={...} />
`}</code></pre>
    <h3>{`Good`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Component
        attribute={...}
        anotherAttribute={...}
        attributeThree={...}
        …
    />
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{` These guidelines are by no means authoritative or exhaustive, but I feel they are a good starting point for organising and standardising React components and some of the more common use cases I encounter. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      